export const NumberFormatterPlugin = {

  install(Vue) {
  
    const locale = 'en-US';
    
    const formatNumberGeneric = (value, decimals = 2, isCurrency = false, currency = 'USD') => {
      
      const options = {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      };

      if (isCurrency) {
        options.style = 'currency';
        options.currency = currency;
      }

      return new Intl.NumberFormat(locale, options).format(value);
    
    };
    
    Vue.prototype.$formatNumber = (value, decimals = 0) => formatNumberGeneric(value, decimals);
    Vue.prototype.$formatCurrency = (value, decimals = 2, currency = 'USD') => formatNumberGeneric(value, decimals, true, currency);

  },

};
